import React, { useState } from "react";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";

import { localStorageData } from "services/auth/localStorageData";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ViewAnnouncement from "./ViewParentList";
function Users() {
  const [isEdit, setisEdit] = useState(false);
  const [list, setList] = useState([]);

  const getAllCategoryList = useQuery(
    "news",
    () => userServices.commonGetService(`/admin/fetchAllUser`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        console.log("alll category---");
        console.log(res.data.data);

        /// setList([]);
        setList(res.data);
      },
    }
  );

  return (
    <div>
      <div className="mx-10">
        {getAllCategoryList.isLoading == false && list != "" ? (
          <ViewAnnouncement data={list.data} />
        ) : null}
      </div>
    </div>
  );
}

export default Users;
