import React from "react";
import Grid from "@mui/material/Grid";
import { Route, Routes } from "react-router-dom";

import SidebarAdmin from "components/Sidebar/SidebarAdmin";
import IsLogin from "./IsLogin";

import AuthNavbar from "components/Navbars/AdminNavbar.js";
import AddPlace from "admin/AddPlace";

import Users from "admin/Users";

import EnrollPlace from "admin/EnrollPlace";
import AddAnnouncement from "admin/AddAnnouncement";

function Admin() {
  return (
    <>
      <SidebarAdmin />
      <div className="relative  md:ml-64">
        {/* <AuthNavbar /> */}
        <main>
          <section className=" w-full h-full  py-10 ">
            <Routes>
              <Route element={<IsLogin />}>
                <Route path="addplace" element={<AddPlace />} />
                <Route path="enrollplace" element={<EnrollPlace />} />
                <Route path="addannouncement" element={<AddAnnouncement />} />

                <Route path="users" element={<Users />} />
              </Route>
            </Routes>
          </section>
        </main>
      </div>
    </>
  );
}

export default Admin;
