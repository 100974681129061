import { Outlet, useLocation } from "react-router-dom";
import React from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { localToken } from "config/config";
import { useDispatch, useSelector } from "react-redux";
import { store } from "reactStore/MainStore";
import { localStorageData } from "services/auth/localStorageData";

export default function IsLogin() {
  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    let localData = JSON.parse(localStorage.getItem("admintoken"));

    if (localData == null) {
      navigate("/login");
    }
  }, [location, navigate]);

  return <Outlet />;
}
