import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { localToken, endPoint, ImageEndPoint } from "config/config";
import TextField from "@mui/material/TextField";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "address", label: "Address", minWidth: 170 },
  // { id: 'category', label: 'category', minWidth: 170 },

  { id: "vistors", label: "vistors", minWidth: 170 },
  { id: "option", label: "Options", minWidth: 170 },
];

function ViewPlaces(props) {
  console.log("--------------------props.data", props.data);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [copyList, setCopyList] = useState(props.data);
  const requestSearch = (searched) => {
    setCopyList(props.data.filter((item) => item.name.includes(searched)));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteService = (row) => {
    const r = window.confirm("Do You Really Want to Delete It ?");
    if (r == true) {
      props.deleteService.mutate({ placeId: row._id });
      // console.log(row);
    }
  };
  const editService = (row) => {
    props.setisEdit(true);

    console.log(row._id);

    props.formik.setFieldValue("name", row.name);

    // props.formik.setFieldValue('category', row.category);
    // props.formik.setFieldValue('pics', row.pics);
    props.formik.setFieldValue("address", row.address);
    props.formik.setFieldValue("lat", row.lat);
    props.formik.setFieldValue("long", row.long);
    props.formik.setFieldValue("atmosphere", row.atmosphere);
    props.formik.setFieldValue("description", row.description);

    props.formik.setFieldValue("placeId", row._id);

    let url = ImageEndPoint + row.pic;

    const toDataURL = (url) =>
      fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
                var output = document.getElementById("output");
                output.src = reader.result;
              };

              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    toDataURL(url).then(async (dataUrl) => {
      var fileData = await dataURLtoFile(dataUrl, "this is new_name.jpeg");

      props.formik.setFieldValue("pics", fileData);
    });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TextField
        variant="outlined"
        placeholder="search..."
        type="search"
        onInput={(e) => requestSearch(e.target.value)}
      />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(copyList.length > 0 ? copyList : props.data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];

                    if (column.id === "option") {
                      return (
                        <div className="m-4">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editService(row)}
                          >
                            <i class="fas fa-edit" />
                          </IconButton>
                          &nbsp;
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteService(row)}
                          >
                            <i class="fas fa-trash text-sm" />
                          </IconButton>
                        </div>
                      );
                    } else if (column.id === "vistors") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : row.dataplaces.length != 0
                            ? row.dataplaces[0].vistor
                            : 0}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}

            {/*  props.data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          if (column.id === 'option') {
                            return (
                              <div className='m-4'>
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => editService(row)}
                                >
                                  <i class='fas fa-edit' />
                                </IconButton>
                                &nbsp;
                                <IconButton
                                  aria-label='delete'
                                  onClick={() => deleteService(row)}
                                >
                                  <i class='fas fa-trash text-sm' />
                                </IconButton>
                              </div>
                            );
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ViewPlaces;
