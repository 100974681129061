import React, { useState } from 'react';
import Container from '@mui/material/Container';

import { useFormik } from 'formik';

import * as Yup from 'yup';
// import ViewUser from 'Admin/ViewUser';
import Category from 'components/Data/index';
import { useMutation, useQuery } from 'react-query';
import ErrorService from 'services/formatError/ErrorService';
import userServices from 'services/httpService/userAuth/userServices';

import Button from '@mui/material/Button';

import { toast } from 'react-toastify';
import ViewPlaces from 'admin/ViewPlaces';
import AutoAddress from 'components/AutoAddress';
import Select from 'react-select';

function AddPlace() {
  const [isEdit, setisEdit] = useState(false);
  const [listPlaces, setListPlaces] = useState([]);

  const onChangeHandler = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('output');
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      formik.setFieldValue('pics', file);

      if (isEdit == true) {
        const formData = new FormData();
        formData.append('pics', file);
        formData.append('_id', formik.values._id);

        updateImagePlaces.mutate(formData);
      }
      // formData.append('pics', e.target.files[0]);
    }
  };

  const getAllPlaces = useQuery(
    'ordersData',
    () => userServices.commonGetService(`/places/getAllPlaces`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        console.log(res.data);
        setListPlaces(res.data);
      },
    }
  );

  let user = {
    // _id: '',
    placeId: '',
    name: '',
    description: '',
    pics: '',
    category: '',
    address: '',
    atmosphere: '',
    lat: '',
    long: '',
  };

  const [userSechema, setuserSechema] = useState(user);
  const formik = useFormik({
    initialValues: userSechema,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'Must be more than 4 characters')
        .required('Required'),
      pics: Yup.string().required('Required'),

      category: Yup.string().required('Required'),
      address: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      console.log(values.category);
      // let finalcategory = values.category.map(function (obj) {
      //   return obj.value;
      // });
      console.log(values);
      const formData = new FormData();
      formData.append('pics', values.pics);
      formData.append('name', values.name);
      formData.append('description', values.description);
      // formData.append('category', values.category);
      formData.append('category[]', [values.category]);
      /// finalcategory.forEach((item) => formData.append('category[]', item));
      formData.append('atmosphere', values.atmosphere);
      formData.append('address', values.address);
      formData.append('lat', values.lat);
      formData.append('long', values.long);
      if (isEdit === false) {
        addNewPlaces.mutate(formData);
      } else {
        formData.append('placeId', values.placeId);
        updateService.mutate(formData);
        console.log(values);
      }
    },
  });

  const addNewPlaces = useMutation(
    (NewPlaces) =>
      userServices.commonPostService('/places/uploadPlace', NewPlaces),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllPlaces.refetch();

        setuserSechema(user);
        formik.resetForm({ NewPlaces: '' });
        toast.success('Place has been created');
      },
    }
  );
  // eslint-disable-next-line

  const updateImagePlaces = useMutation(
    (updateImage) =>
      userServices.commonPostService('/admin/updateImagePlaces', updateImage),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllPlaces.refetch();

        // setuserSechema(user);
        // formik.setFieldValue('name', '');
        // formik.setFieldValue('keywords', []);
        // formik.setFieldValue('description', '');
        // toast.success('Disease has been created');
      },
    }
  );

  const updateService = useMutation(
    (updateNow) =>
      userServices.commonPostService('/places/updatePlace', updateNow),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllPlaces.refetch();
        setuserSechema(user);
        setisEdit(false);
        formik.resetForm({ updateNow: '' });
        toast.success('updated');
      },
    }
  );

  const deleteService = useMutation(
    (deleteService) =>
      userServices.commonPostService('/places/deletePlace', deleteService),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success('Places has been deleted');
        getAllPlaces.refetch();
      },
    }
  );

  function changeaddress(address, lat, long) {
    console.log(long);
    formik.setFieldValue('address', address);
    formik.setFieldValue('lat', lat);
    formik.setFieldValue('long', long);
  }

  return (
    <div>
      <Container maxWidth='sm'>
        <div className='flex-auto px-2 lg:px-10 py-10 pt-0'>
          <div className='text-blueGray-400 text-center text-xl mb-3 font-bold'>
            {isEdit === false ? <span>Add </span> : <span>Update </span>}
            Places
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='relative w-full mb-3'>
              <div className='center-styl'>
                <img
                  id='output'
                  src='https://ui-avatars.com/api/?name=John+Doe'
                  className='w-24 h-24 rounded object-cover'
                  alt=''
                />
              </div>

              <div className='center-styl'>
                <label htmlFor='contained-button-file' className='mt-6'>
                  <input
                    accept='image/*'
                    id='contained-button-file'
                    type='file'
                    onChange={onChangeHandler}
                    className='img-upload'
                  />
                  <Button variant='contained' component='span'>
                    Upload
                  </Button>
                </label>
              </div>

              {formik.touched.pics && formik.errors.pics ? (
                <div className='text-red-500 text-xs mt-2'>
                  {formik.errors.pics}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Name
              </label>
              <input
                id='name'
                name='name'
                type='text'
                className='input-styl'
                placeholder='Name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className='text-red-500 text-xs'>{formik.errors.name}</div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Category
              </label>

              {/* <Select
                // defaultValue={[colourOptions[2], colourOptions[3]]}
                isMulti
                name='colors'
                options={Category}
                className='basic-multi-select'
                classNamePrefix='select'
                value={formik.values.category}
                onChange={(e) => formik.setFieldValue('category', e)}
              /> */}

              <select
                name='category'
                id='category'
                className='input-styl h-12'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category}
              >
                <option value='' disabled selected>
                  Category
                </option>
                {Category.map((s, i) => (
                  <option value={s.value}>{s.value}</option>
                ))}
              </select>

              {formik.touched.category && formik.errors.category ? (
                <div className='text-red-600'>{formik.errors.category}</div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Category
              </label>
              <AutoAddress
                label='Location'
                changeaddress={changeaddress}
                value={formik.values.address}
              />
              {formik.touched.address && formik.errors.address ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.address}
                </div>
              ) : null}

              <div>{formik.values.address}</div>
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Atmosphere
              </label>
              <input
                id='atmosphere'
                name='atmosphere'
                type='text'
                className='input-styl'
                placeholder='Atmosphere'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.atmosphere}
              />
              {formik.touched.atmosphere && formik.errors.atmosphere ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.atmosphere}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Description
              </label>
              <textarea
                type='text'
                rows='4'
                cols='50'
                name='description'
                id='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                className='input-styl desc-styl'
                placeholder='Description'
              ></textarea>
              {formik.touched.description && formik.errors.description ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.description}
                </div>
              ) : null}
            </div>

            <div className='text-center mt-6'>
              <button className='btn-styl' type='submit'>
                {isEdit === false ? <>Add </> : <>Update </>} Place
              </button>
            </div>
          </form>
        </div>
        {/* eslint-disable-next-line */}
      </Container>
      <div className='mx-10'>
        {getAllPlaces.isLoading == false && listPlaces != '' ? (
          <ViewPlaces
            data={listPlaces.data}
            deleteService={deleteService}
            formik={formik}
            setisEdit={setisEdit}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AddPlace;
