import React from 'react'

export default function SocialButton() {
  return (
    <div>
      <div className='flex mb-4'>
        <a
          className='bg-black text-white flex p-2 py-3 pr-4 rounded shadow mx-6 hover:scale-125 transition duration-500'
          href='https://play.google.com/store/apps/details?id=com.encodersoft.eliteoutt'
          target='_blank'
        >
          <img
            src='https://img.icons8.com/external-those-icons-flat-those-icons/344/external-Play-Store-logos-and-brands-those-icons-flat-those-icons.png'
            class=' h-6 w-6 m-1 mx-2 '
            alt='Logo'
          />
          <div className='my-1'>Google Play</div>
        </a>

        <a
          className='bg-black text-white flex p-2 py-3 pr-4 rounded shadow mx-6 hover:scale-125 transition duration-500'
          href='https://apps.apple.com/us/app/elite-outt/id1644514543'
          target='_blank'
        >
          <i class='fab fa-apple  m-1 mx-2 text-2xl'></i>
          <div className='my-1'>Apple Store</div>
        </a>
      </div>
    </div>
  );
}
