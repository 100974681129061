import React from 'react';
import history from '../history';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main';
import Admin from './Admin';
import AddPlace from 'admin/AddPlace';
import AdminLogin from 'admin/AdminLogin';
import Policy from 'pages/StaticPages/Policy';
import Terms from 'pages/StaticPages/Terms';

function Root() {
  return (
    <Router history={history}>
      <main>
        <section className='relative w-full  h-full  min-h-screen '>
          <Routes>
            <Route path='admin/*' element={<Admin />} />
            <Route path='/login' element={<AdminLogin />} />
            <Route path='/' element={<Main />} />
            <Route path='/privacy' element={<Policy />} />
            <Route path='/terms' element={<Terms />} />
          </Routes>
        </section>
      </main>
    </Router>
  );
}

export default Root;
