import React from 'react';
import Grid from '@mui/material/Grid';
import SocialButton from 'components/SocialButton';
// import image1 form "";




function LandingPage() {
  return (
    <>
      <nav>
        <Grid className='backImage-wrapper' container spacing={2}>
          <Grid className='logo-wrapper' item xs={6} sm={8}>
            <p>Elite Outt</p>
          </Grid>
          <Grid className='navBar' item xs={6} sm={4}>
            <ul>
              <li>
                <a href='/login'>Sign in</a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <div className='text-wrapper'>
              <h1>Welcome to Elite outt!!!</h1>
              <p className='text-slate-500'>
                Currently being used in the city of Boston.
              </p>
            </div>
            <div className='flex center-styl mt-6'>
              <SocialButton />
            </div>
          </Grid>
          <Grid item xs={8}></Grid>
        </Grid>
      </nav>

      {/* Grid 2 hero section image */}
      <Grid container spacing={2}>
        <Grid className='grid-2' item xs={12}>
          <div className='hero-image-wrapper'>
            <img
              className='object-contain'
              src='https://img.freepik.com/premium-photo/blue-location-pin-sign-icon-gps-navigation-map-road-direction-internet-search-bar-technology-symbol-position-place-background-with-find-route-mark-travel-destination-navigator-3d-rendering_79161-1996.jpg?w=2000'
              alt=''
            />
          </div>
        </Grid>
      </Grid>

      {/* Grid 3 text and button */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='grid-3-wrapper'>
            <p>
              The one place to find the perfect vibe and atmosphere you are
              looking for. No more reading tons of reviews to see if that place
              is the right fit.
            </p>

            <div className='flex center-styl mt-6'>
              <SocialButton />
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Mobile Image Grid-4 with text on right  */}

      <Grid className='mobile-grid-4' container spacing={10}>
        <Grid className='mobile-sub-grid' item xs={12} sm={12} md={5} lg={5}>
          <div className='mobile-image-wrapper'>
            <img
              src={require('assets/img/1.jpeg')}
              className='object-contain'
              alt=''
            />
          </div>
        </Grid>
        <Grid
          className='grid-4-text-wrapper heading-text-wrapper para-text-wrapper'
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
        >
          <div>
            <h1>Sign in to get started</h1>
            <p>
              Help other people find a great place by quickly answering a few
              questions about where you went so the next time the app can filter
              out the right place for everyone.
            </p>

            <div className='flex center-styl mt-6'>
              <SocialButton />
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Mobile Image Grid-5 with text on left */}
      <Grid className='mobile-grid-5' container spacing={2}>
        <Grid
          className='grid-5-text-wrapper heading-text-wrapper para-text-wrapper'
          item
          xs={12}
          sm={12}
          md={6}
          lg={7}
        >
          <div>
            <h1>Places to Make it happen</h1>
            <p>
              Help other people find a great place by quickly answering a few
              questions<br></br> about where you went so the next time the app
              can filter out<br></br> the right place for everyone.
            </p>
            <div className='flex center-styl mt-6'>
              <SocialButton />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <div className='mobile-2-image-wrapper mb-4'>
            <img
              src={require('assets/img/4.jpeg')}
              alt=''
              className='object-contain'
            />
          </div>
        </Grid>
      </Grid>

      {/* Mobile Image Grid-6 with text on right */}

      <Grid className='mobile-grid-6' container spacing={10}>
        <Grid className='mobile-sub-grid' item xs={12} sm={12} md={6} lg={5}>
          <div className='mobile-image-wrapper'>
            <img
              src={require('assets/img/6.jpeg')}
              alt=''
              className='object-contain'
            />
          </div>
        </Grid>
        <Grid
          className='grid-6-text-wrapper heading-text-wrapper para-text-wrapper'
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <div className='btn-hover'>
            <h1>Simple reviews questions </h1>
            <p>
              Whether you are looking to go out for a date night with intimate
              lighting or a lively spot with friends with just the right music,
              <br></br>this app will help you find your destination.
            </p>
            <div className='flex center-styl mt-6'>
              <SocialButton />
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Footer */}
      <footer className='mb-10'>
        <Grid className='' container spacing={2}>
          <Grid item xs={12}>
            <div className='grid-7-text-wrapper'>
              <h1>Always feel Elite while outt</h1>
            </div>
            <div className='button-wrapper'>
              <div className='flex center-styl mt-6'>
                <SocialButton />
              </div>
            </div>
          </Grid>

          <Grid className='footer mb-10' item xs={12}>
            <ul>
              <li>
                <a href='/privacy' target='_blank'>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href='/terms' target='_blank'>
                  Terms and Condition
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}

export default LandingPage;
