import React, { useState } from 'react';
import Container from '@mui/material/Container';

import { useFormik } from 'formik';

import * as Yup from 'yup';

import { useMutation, useQuery } from 'react-query';
import ErrorService from 'services/formatError/ErrorService';
import userServices from 'services/httpService/userAuth/userServices';

import Button from '@mui/material/Button';

import { toast } from 'react-toastify';
import ViewPlaces from 'admin/ViewPlaces';

function EnrollPlace() {
  const [listPlaces, setListPlaces] = useState([]);

  const getAllPlaces = useQuery(
    'ordersData',
    () => userServices.commonGetService(`/places/getAllPlaces`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        console.log(res.data.data);
        setListPlaces(res.data.data);
      },
    }
  );

  let user = {
    placeId: '',
    category: '',
  };

  const [userSechema, setuserSechema] = useState(user);
  const formik = useFormik({
    initialValues: userSechema,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values) => {
      console.log(values.placeId);
      enrollCategory.mutate({ placeId: values.placeId });
    },
  });

  const enrollCategory = useMutation(
    (NewCategory) =>
      userServices.commonPostService('/places/enrollPlace', NewCategory),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setuserSechema(user);
        formik.resetForm({ NewPlaces: '' });
        toast.success('Enroll Place');
      },
    }
  );

  return (
    <div>
      <Container maxWidth='sm'>
        <div className='flex-auto px-2  pt-0'>
          <div className='text-blueGray-400 text-center text-xl mb-3 font-bold'>
            <form onSubmit={formik.handleSubmit}>
              <div className='relative w-full mb-4'>
                <label
                  className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                  htmlFor='grid-name'
                >
                  Name
                </label>
                {listPlaces != '' ? (
                  <select
                    name='placeId'
                    id='placeId'
                    className='input-styl h-12 mb-2'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.placeId}
                  >
                    <option value='' disabled selected>
                      Name
                    </option>

                    {listPlaces.map((s, i) => (
                      <>
                        <option value={s._id}>{s.name}</option>
                      </>
                    ))}
                  </select>
                ) : (
                  'loading'
                )}

                {formik.touched.category && formik.errors.category ? (
                  <div className='text-red-600 text-xs'>
                    {formik.errors.category}
                  </div>
                ) : null}
              </div>

              <button className='btn-styl' type='submit'>
                Enroll
              </button>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default EnrollPlace;
