import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { localToken, endPoint, ImageEndPoint } from "config/config";
import TextField from "@mui/material/TextField";

const columns = [
  { id: "email", label: "email", minWidth: 170 },
  { id: "username", label: "user Name", minWidth: 170 },
  { id: "fname", label: "Name", minWidth: 170 },
  // { id: "totalReviews", label: "total Reviews", minWidth: 170 },
  // { id: "rating", label: "rating", minWidth: 170 },
  // { id: "blance", label: "blance", minWidth: 170 },

  // { id: 'category', label: 'category', minWidth: 170 },
];

export default function ViewParentList(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [copyList, setCopyList] = useState(props.data);
  const requestSearch = (searched) => {
    setCopyList(props.data.filter((item) => item.name.includes(searched)));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteService = (row) => {
    console.log("row=>", row);
    const r = window.confirm("Do You Really Want to Delete It ?");
    if (r == true) {
      props.deleteService.mutate({ _id: row._id });
      // console.log(row);
    }
  };

  const editService = (row) => {
    console.log("------row---", row);
    props.setisEdit(true);
    props.formik.setFieldValue("name", row.name);

    props.formik.setFieldValue("desc", row.desc);
    props.formik.setFieldValue("_id", row._id);

    let url = ImageEndPoint + row.image;

    const toDataURL = (url) =>
      fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
                var output = document.getElementById("output");
                output.src = reader.result;
              };

              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    toDataURL(url).then(async (dataUrl) => {
      console.log("Here is Base64 Url", dataUrl);
      var fileData = await dataURLtoFile(dataUrl, "this is new_name.jpeg");
      console.log("Here is JavaScript File Object", fileData);

      props.formik.setFieldValue("pics", fileData);
    });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TextField
        variant="outlined"
        placeholder="search..."
        type="search"
        onInput={(e) => requestSearch(e.target.value)}
      />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(copyList.length > 0 ? copyList : props.data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
